const colors = {
    appBarBackground: '#F8F8F8',
    lightShadow: '#F0F0F0',
    black: '#000000',
    button: '#9bc4d0',
    darkGray: '#222222',
    focusedField: '#a389bf',
    main: '#9bc4d0',
    lightMain: '#C89EEC',
    lighterMain: 'rgba(155,196,208,0.5)',
    lightBorderGray: '#E1E1E1',
    lightGray: '#F3F0F0',
    lightDarkerGray: '#BFBFBF',
    darkIcon: '#263238',
    labelColor: '#929292',
    lightWarning: 'rgba(255, 0, 0, 0.20)',
    midGray: '#C4C4C4',
    midDarkerGray: '#8a8f8d',
    warning: '#FF0000',
    white: '#FFFFFF',
    userBar: '#a389bf',
}

export default colors
